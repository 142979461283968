<template>
  <!-- 确认预约或挂号 -->
  <div class="handle">
    <SwitchPeople />
    <div class="doctorIntroduce">
      <div class="doctor-information">
        <div class="doctor-head-portrait">
          <img
            @click="enlargeImg"
            :src="
              pre.photo.length == 0
                ? require('@/static/img/tou.webp')
                : pre.photo
            "
            alt=""
          />
        </div>
        <!-- 医生个人信息 -->
        <div class="doctor-name">
          <span class="name">
            <span class="in-size">
              {{ pre.doc_name.length == 0 ? pre.dept_name : pre.doc_name }}
            </span>
            <span class="small-size">{{ doc_rank[pre.treat_type] }}</span>
          </span>
        </div>
      </div>
      <!-- 医生介绍 -->
      <div class="doctor-introduce small-size line">
        <div
          :class="showIntroduce ? '' : 'shrinkIntroduce'"
          @click="showIntroduce = !showIntroduce"
        >
          {{ pre.docIntroduce }}
        </div>
      </div>
    </div>
    <div v-show="showDocImage" class="enlargeArea" @click="enlargeImg">
      <img
        :src="
          pre.photo.length == 0 ? require('@/static/img/tou.webp') : pre.photo
        "
        alt=""
      />
    </div>
    <div class="handle-card">
      <div class="handle-row-one">
        <span>挂号费用</span>
        <span style="color:red">
          {{
            pre.treat_fee == undefined ||
            pre.treat_fee == null ||
            pre.treat_fee == ''
              ? '---'
              : pre.treat_fee + '元'
          }}
        </span>
      </div>
      <div class="handle-information">
        <!-- <div>
          <span>号源:</span>
          <span>{{reg_type == 1 ? pre.visit_seq_no : $route.query.sort_no  }}</span>
        </div> -->
        <div>
          <span>科室名称:</span>
          <span>{{ pre.dept_name }}</span>
        </div>
        <div>
          <span>医生名称:</span>
          <span>
            {{
              $route.query.item.doc_name.length == 0
                ? '普通医生'
                : $route.query.item.doc_name
            }}
          </span>
        </div>
        <div>
          <span>就诊日期:</span>
          <span>{{ $route.query.visitdate }}</span>
        </div>
        <div>
          <span>时间段:</span>
          <span>
            {{ $route.query.timeslot }}
          </span>
        </div>
      </div>
    </div>
    <div class="tips">挂号后，请先到自助机取号，方可候诊</div>
    <van-button
      class="handle-button"
      type="primary"
      size="large"
      block
      @click="handleClick"
    >
      确认挂号
    </van-button>
  </div>
</template>

<script>
import { saveTradeLog } from 'network/service'
import { qrcode, loopQuery } from 'network/payment'
import SwitchPeople from '@/components/common/switchPeople/switchPeople'
import { mapState } from 'vuex'
import { Debounce } from '@/common/utils'
export default {
  name: 'handle',
  data() {
    return {
      //挂号信息
      pre: [],
      //挂号或预约
      list: '',
      //标题
      title: this.$route.query.dept_name,
      obj: this.$route.query,
      doc_rank: {
        '0': '普通医生',
        '001': '一般医师',
        '002': '主治医师',
        '003': '副主任医师',
        '004': '主任医师',
        '005': '专家',
        '006': '免费',
        '007': '京津专家',
        '008': '急诊',
        '009': '特殊疾病',
        '010': '主任中医师',
        '011': '副主任中医师',
        '012': '中医师',
        '013': '简易门诊',
      },
      showIntroduce: false,
      showDocImage: false,
    }
  },
  components: {
    SwitchPeople,
  },
  created() {
    this.pre = this.$route.query.item
    console.log(this.pre)
  },
  mounted() {
    // switch (this.reg_type) {
    //   case '1':
    //     //挂号请求
    //     this.registerPreSettlementPost()
    //     break
    // }
  },
  computed: {
    ...mapState(['reg_type', 'seq', 'cardList', 'dept_id', 'head', 'med_id']),
  },
  methods: {
    enlargeImg() {
      this.showDocImage = !this.showDocImage
    },
    onBridgeReady(params) {
      console.log(params.data.appId, params)
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: params.data.appId, //公众号名称，由商户传入
          timeStamp: params.data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: params.data.nonceStr, //随机串
          package: params.data.package,
          signType: params.data.signType, //微信签名方式：
          paySign: params.data.paySign, //微信签名
        },
        function(res) {
          console.log(res)
          // if (res.err_msg == "get_brand_wcpay_request:ok") {
          //   console.log(1)
          // }
        }
      )
    },
    //确认挂号
    handleClick: Debounce(async function handleClick() {
      //挂号
      if (this.$route.query.reg_type == '0') {
        //扫码下单接口
        let res = await qrcode({
          seq: this.seq,
          urlString: this.$urlString,
          appKey: '17b1a89be880001',
          appSecret: '33cb874e10bb409a84f71d29f49ca017',
          mchNo: 'lffbyy',
          billNo: this.cardList[0].openId + new Date().getTime(),
          accountId: '105000080629028',
          sceneId: 'zyzf',
          totalAmount: parseInt(this.pre.treat_fee).toFixed(2) * 100,
          subject: '挂号费',
        })
        if (res.code == '0') {
          //保存支付总订单表日志记录
          await saveTradeLog({
            seq: this.seq,
            stat: 'PAYING',
            total_charge_amt: Number(this.pre.treat_fee).toFixed(2),
            person_pay: Number(this.pre.treat_fee).toFixed(2),
            channel: '建行聚合支付',
            trade_no: res.data.trade_no,
            med_pay: '1',
            business_type: '挂号',
          })
          let y = new Date().getFullYear()
          let m = new Date().getMonth() + 1
          m = m < 10 ? '0' + m : m
          let d = new Date().getDate()
          d = d < 10 ? '0' + d : d
          let date = y + '-' + m + '-' + d
          let newdate = m + d
          //下单轮询
          let outcome = await loopQuery({
            seq: this.seq,
            urlString: this.$urlString,
            mchNo: 'lffbyy',
            appKey: '17b1a89be880001',
            appSecret: '33cb874e10bb409a84f71d29f49ca017',
            trade_no: res.data.trade_no,
            tradeNo: res.data.trade_no,
            order_id: this.$route.query.item.order_id,
            doc_no: this.$route.query.item.doc_no,
            card_no: this.cardList[0].cardNo,
            card_type: this.cardList[0].cardType,
            med_id: this.cardList[0].cardNo,
            treat_mode: this.$route.query.visitdate == date ? '1' : '2',
            treat_type: this.$route.query.item.treat_type,
            treat_date: this.$route.query.visitdate,
            treate_date:
              this.$route.query.visitdate + this.$route.query.timeslot,
            // shift: this.pre.treat_type.shift,
            dept_id: this.dept_id,
            total_charge_amt: Number(this.$route.query.item.treat_fee).toFixed(
              2
            ),
            person_pay: Number(this.$route.query.item.treat_fee).toFixed(2),
            payway: '0',
            dept_name: this.pre.dept_name,
            doc_name:
              this.pre.doc_name.length == 0 ? '普通医生' : this.pre.doc_name,
            doc_rank: this.doc_rank[this.pre.treat_type],
            source: '挂号',
            bankno: res.data.trade_no,
            bankcard_info: newdate + '|' + this.cardList[0].cardNo,
            shift: this.$route.query.shift,
            name: this.cardList[0].name,
            refundReason: '业务失败退还',
          })
          if (outcome.code == 0) {
            //调用下单页面
            window.location.href = res.data.codeUrl
          } else {
            this.$toast(res.msg)
          }
        } else {
          this.$toast(res.msg)
        }
      }
    }, 1500),
  },
}
</script>

<style lang="less" scoped>
.handle {
  overflow: auto;
  height: 100vh;
  .doctorIntroduce {
    .doctor-information {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      .doctor-head-portrait {
        margin: 0 1rem;
        height: 4.5rem;
        width: 4rem;
        img {
          border-radius: 50%;
          height: 100%;
          width: 100%;
        }
      }
      .doctor-name {
        .name {
          display: flex;
          flex-direction: column;
          > span {
            line-height: 1.5rem;
            padding-right: 1rem;
          }
        }
      }
    }
    .doctor-introduce {
      padding: 0rem 1rem 0.5rem 1rem;
      // .shrinkIntroduce{
      //   height: 3rem;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }
      .shrinkIntroduce {
        display: -webkit-box; /*将对象转为弹性盒模型展示*/
        -webkit-box-orient: vertical; /*设置弹性盒模型子元素的排列方式*/
        -webkit-line-clamp: 2; /*限制文本行数*/
        overflow: hidden; /*超出隐藏*/
      }
    }
  }
  .enlargeArea {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    img {
      width: 10rem;
      margin-top: 50%;
      margin-left: 50%;
      transform: translate(-50%);
      // height: 100%;
    }
  }
  .handle-card {
    margin: 0.7rem auto 2rem auto;
    padding: 0.5rem 0;
    border-radius: 1rem;
    width: 90vw;
    background-color: @cardColor;
    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
    .handle-row-one {
      margin: auto;
      border-bottom: 2px dashed #efefef;
      width: 95%;
      line-height: 3rem;
      span:nth-child(1) {
        padding-left: 1rem;
        font-size: 1.2rem;
        color: rgb(131, 131, 131);
      }
      span:nth-child(2) {
        float: right;
        padding-right: 2rem;
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
    .handle-information {
      div {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 95%;
        line-height: 2.5rem;
        span:nth-child(1) {
          color: rgb(131, 131, 131);
          flex: 0.3;
          padding-left: 1rem;
        }
        span:nth-child(2) {
          flex: 0.7;
          padding-left: 2rem;
        }
      }
    }
  }
  .tips {
    margin: 2rem 0;
    text-align: center;
    color: red;
    font-weight: bold;
  }
  .handle-button {
    border-radius: 5rem;
    width: 90vw;
    margin: auto;
    background: @darkColor;
    border-color: @darkColor;
  }
}
</style>
